import { AiOutlineArrowRight } from "react-icons/ai";
import img1 from "../images/Decals-Graphics-1.jpg";
import { Link } from "react-router-dom";
let DecalsGData = () => {
    return (
        <>
            <header className="banner-style">
                <h1 className='banner-heading'>Decals & Graphics</h1>
                <div className="banner-div">
                    <Link to="/" className="banner-link">Home </Link>
                    <AiOutlineArrowRight className="banner-icon" />
                    <p className="banner-p">Decals & Graphics</p>
                </div>
            </header>
            <section className="service-style">
                <div className="service-div">
                    <div className="lg:w-1/2">
                        <h2 className="heading2">Transform Your Space with Decals & Graphics: Versatile, Eye-Catching Solutions for Every Need</h2>
                        <p className="paragraphs">
                        In the competitive world of marketing and advertising, standing out from the crowd is essential. Decals and graphics offer a dynamic and flexible way to promote your brand, convey your message, and captivate your audience.
                        </p>
                        <p className="paragraphs">
                        Whether you need vibrant wall murals, eye-catching floor graphics, or custom stickers and labels, decals provide a versatile solution that can adapt to any setting.
                        </p>
                        <p className="paragraphs">Let’s explore the advantages and common uses of decals and graphics compared to other types of signage, and highlight our premium offerings, including floor graphics, wall decals, wallpaper, hoarding signage, wall murals, stickers and labels, vinyl lettering, wall graphics, and perforated graphics.
                        </p>
                    </div>
                    <div className="lg:w-1/2 lg:mt-0 lg:pl-8 flex justify-center items-center">
                        <img className="rounded shadow-lg w-3/4" src={img1} alt="Signage" />
                    </div>
                </div>
                <div className="max-w-7xl mx-auto">
                    <h2 className="heading2">The Decals & Graphics Advantage: Why Choose These Solutions?</h2>
                    <h3 className="heading3">1. Versatility and Customization</h3>
                    <ul className="my-ul">
                        <li><b>Tailored Designs:</b> Decals and graphics can be customized to fit any space or design requirement, offering endless possibilities for creativity and branding.</li>
                        <li><b>Variety of Applications:</b> From walls and floors to windows and vehicles, decals can be applied to virtually any surface, making them highly versatile.</li>
                    </ul>
                    <h3 className="heading3">2. Cost-Effective Marketing</h3>
                    <ul className="my-ul">
                        <li><b>Affordable Production:</b> Compared to traditional signage, decals are relatively inexpensive to produce, offering a budget-friendly option for businesses of all sizes.</li>
                        <li><b>Easy Installation and Removal:</b>  Decals are simple to install and remove, allowing for easy updates and changes without significant cost or effort.</li>
                    </ul>
                    <h3 className="heading3">3. Durability and Quality</h3>
                    <ul className="my-ul">
                        <li><b>Long-Lasting Materials:</b> High-quality decals are designed to withstand the elements, ensuring they remain vibrant and intact for extended periods, whether used indoors or outdoors.</li>
                        <li><b>Professional Appearance:</b> Modern printing technologies ensure that decals and graphics are sharp, colorful, and professional-looking.</li>
                    </ul>
                    <h3 className="heading3">4. Space-Saving and Lightweight</h3>
                    <ul className="my-ul">
                        <li><b>Compact Storage: </b> Unlike bulky traditional signs, decals are lightweight and easy to store, making them ideal for temporary installations and events.</li>
                        <li><b>Non-Intrusive: </b> Decals can transform a space without taking up physical room, providing impactful branding without clutter.</li>
                    </ul>

                    <h2 className="heading2">Common Uses of Decals & Graphics</h2>
                    <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
                    Decals and graphics are incredibly versatile, finding applications in numerous scenarios:
                    </p>
                    <ul className="my-ul">
                        <li><b>Retail and Storefronts:</b> Use wall decals, vinyl lettering, and window graphics to attract customers, showcase promotions, and enhance your store’s aesthetic.</li>
                        <li><b>Corporate Offices:</b> Wall murals and graphics can create an engaging and inspiring work environment, reinforcing company values and branding.</li>
                        <li><b>Events and Trade Shows: </b> Floor graphics, banners, and stickers can guide attendees, highlight key areas, and make your booth stand out.</li>
                        <li><b>Restaurants and Cafes:</b> Custom wallpaper and wall decals can create a unique ambiance, making your space memorable and inviting.</li>
                        <li><b>Construction Sites:</b> Hoarding signage turns construction barriers into powerful advertising tools, building anticipation for new developments.</li>
                        <li><b>Vehicles:</b> Perforated graphics and vinyl wraps transform cars, trucks, and buses into mobile billboards, reaching a wider audience.</li>

                    </ul>

                    <h2 className="heading2">Decals & Graphics vs. Other Types of Signage</h2>
                    <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
                    While traditional signage has its place, decals and graphics offer unique benefits that make them a preferred choice for many applications:
                    </p>
                    <ul className="my-ul">
                        <li><b>Traditional Signage:</b> Often more permanent and less flexible, traditional signs can be bulky and difficult to move or update.</li>
                        <li><b>Traditional Signage:</b> While great for outdoor and event settings, banners and flags lack the close-up detail and integration that decals provide for interior spaces.</li>
                        <li><b>Fabric and Textile Signage:</b> Though elegant and versatile, fabric signage does not offer the same level of customization and surface adaptability as decals and graphics.</li>
                    </ul>

                    <h2 className="heading2">Our Expertise: Premium Decals & Graphics Solutions</h2>
                    <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
                    We specialize in a wide range of decal and graphic solutions to meet your specific needs:
                    </p>
                    <ul className="my-ul">
                        <li><b>Floor Graphics:</b> Guide customers and create interactive spaces with durable, eye-catching floor decals.</li>
                        <li><b>Wall Decals and Graphics:</b> Transform blank walls into vibrant canvases with custom-designed graphics that captivate and inspire.</li>
                        <li><b>Wallpaper:</b> Add personality and style to any room with bespoke wallpaper designs that reflect your brand’s identity.</li>
                        <li><b>Hoarding Signage:</b> Utilize construction barriers for impactful advertising with high-quality hoarding graphics.</li>
                        <li><b>Wall Murals:</b> Create stunning visual statements with large-scale murals that make a lasting impression.</li>
                        <li><b>Stickers & Labels:</b> Perfect for branding, packaging, and promotional giveaways, custom stickers and labels are versatile and effective.</li>
                        <li><b>Vinyl Lettering:</b> Crisp and professional, vinyl lettering is ideal for storefronts, office spaces, and directional signage.</li>
                        <li><b>Perforated Graphics:</b> Maximize visibility while maintaining transparency with perforated window graphics that are perfect for vehicle wraps and storefronts.</li>
                    </ul>
                    <p className="text-gray-600 gap-1 mb-3 mt-8 text-justify font-sans">
                    Ready to transform your space with our top-quality decals and graphics? Contact us today to discover how our innovative solutions can elevate your brand and make a lasting impact on your audience!
                    </p>
                </div>
            </section>
        </>
    );
}
export default DecalsGData;