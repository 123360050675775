import Footer from "./Components/footer";
import IlluminatedData from "./Components/illuminated_signs";
import MyNav from "./Components/mynavbar";

let Illuminated = ()=>{
    return(
        <>
        <MyNav/>
        <IlluminatedData/>
        <Footer/>
        </>
    );
}
export default Illuminated;