import Footer from "./Components/footer";
import MyNav from "./Components/mynavbar";
import VehiclesData from "./Components/vehicles_tsigns";

let Vehicles = ()=>{
    return(
        <>
        <MyNav/>
        <VehiclesData/>
        <Footer/>
        </>
    );
}
export default Vehicles;