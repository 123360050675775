import Footer from "./Components/footer";
import MyNav from "./Components/mynavbar";
import OutdoorData from "./Components/outdoorsigns";

let OutdoorSigns = ()=>{
    return(
        <>
        <MyNav/>
        <OutdoorData/>
        <Footer/>
        </>
    );
}
export default OutdoorSigns;