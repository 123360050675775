import { AiOutlineArrowRight } from "react-icons/ai";
import img1 from "../images/Banner-and-Flags.jpg";
import { Link } from "react-router-dom";
let BannersData = () => {
  return (
    <>
      <header className="banner-style">
        <h1 className="banner-heading">
          Banner & Flags
        </h1>
        <div className="banner-div">
          <Link to="/" className="banner-link">
            Home
          </Link>
          <AiOutlineArrowRight className="banner-icon" />
          <p className="banner-p">Banner & Flags</p>
        </div>
      </header>
      <section className="service-style">
        <div className="service-div">
          <div className="lg:w-1/2">
            <h2 className="heading2">
              Unfurl Your Brand’s Potential with Banners & Flags: The Ultimate
              Guide to Eye-Catching Signage
            </h2>
            <p className="paragraphs">
              In the bustling world of advertising, standing out is key to
              capturing attention and making a lasting impression. Banners and
              flags are versatile and impactful tools that can elevate your
              brand visibility and communicate your message effectively. Whether
              you need to attract foot traffic, promote a special event, or
              enhance your trade show booth, banners and flags offer unmatched
              flexibility and appeal.
            </p>
            <p className="paragraphs">
              Let’s explore the advantages and common uses of banners and flags
              compared to other types of signage and decals, and highlight our
              top-tier offerings, including retractable banner stands, teardrop
              banners and flags, vinyl banners, and pillar wraps.
            </p>
          </div>
          <div className="lg:w-1/2 lg:mt-0 lg:pl-8 flex justify-center items-center">
            <img
              className="rounded shadow-lg w-3/4"
              src={img1}
              alt="Signage"
            />
          </div>
        </div>
        <div className="max-w-7xl mx-auto">
          <h2 className="heading2">
            The Power of Banners & Flags: Advantages for Your Business
          </h2>
          <h3 className="heading3">1. High Visibility</h3>
          <ul className="my-ul">
            <li>
              <b>Eye-Catching Designs:</b> With vibrant colors and bold
              graphics, banners and flags naturally draw the eye, making them
              perfect for grabbing attention in busy environments.
            </li>
            <li>
              <b>Elevated Presence:</b> Flags can be placed at heights that
              other signage can’t reach, ensuring visibility from a distance and
              standing out above the crowd.
            </li>
          </ul>
          <h3 className="heading3">
            2. Versatility and Flexibility
          </h3>
          <ul className="my-ul">
            <li>
              <b>Indoor and Outdoor Use:</b> Banners and flags are suitable for
              a variety of settings, from indoor trade shows to outdoor events,
              adapting seamlessly to different environments.
            </li>
            <li>
              <b>Easy Setup and Portability:</b> Lightweight and easy to
              transport, banners and flags can be set up quickly and moved as
              needed, making them ideal for temporary and mobile promotions.
            </li>
          </ul>
          <h3 className="heading3">
            3. Cost-Effective Marketing
          </h3>
          <ul className="my-ul">
            <li>
              <b>Affordable Production:</b> Compared to other types of signage,
              banners and flags are relatively inexpensive to produce, providing
              a high return on investment.
            </li>
            <li>
              <b>Reusable and Durable:</b> Made from durable materials, they can
              be used repeatedly for multiple events, ensuring long-term value.
            </li>
          </ul>
          <h3 className="heading3">4. Customizable</h3>
          <ul className="my-ul">
            <li>
              <b>Tailored Designs:</b> With a wide range of shapes, sizes, and
              materials, banners and flags can be customized to perfectly fit
              your brand’s message and aesthetic.
            </li>
            <li>
              <b>Versatile Applications:</b> From promotional messages to brand
              logos, they can be tailored to suit any campaign or occasion.
            </li>
          </ul>

          <h2 className="heading2">
            Common Uses of Banners & Flags
          </h2>
          <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
            Banners and flags are incredibly versatile, finding applications in
            numerous scenarios:
          </p>
          <ul className="my-ul">
            <li>
              <b>Trade Shows and Exhibitions:</b> Use retractable banner stands
              and vinyl banners to create impactful booth displays that attract
              visitors.
            </li>
            <li>
              <b>Retail and Storefronts:</b> Teardrop banners and pillar wraps
              can highlight promotions, grand openings, and special offers,
              drawing in customers from the street.
            </li>
            <li>
              <b>Corporate Events and Conferences:</b> Enhance your event space
              with professional, branded banners and flags that create a
              cohesive and polished look.
            </li>
            <li>
              <b>Outdoor Events and Festivals:</b> Durable and
              weather-resistant, flags and banners are perfect for outdoor
              advertising, guiding attendees and showcasing sponsors.
            </li>
          </ul>

          <h2 className="heading2">
            Banners & Flags vs. Other Types of Signage and Decals
          </h2>
          <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
            While traditional signage and decals have their place, banners and
            flags offer distinct advantages:
          </p>
          <ul className="my-ul">
            <li>
              <b>Traditional Signage:</b> Often permanent and less flexible,
              traditional signs are not as portable or adaptable for temporary
              events.
            </li>
            <li>
              <b>Decals:</b> Typically used for smaller, fixed displays, decals
              lack the high visibility and portability of banners and flags.
            </li>
            <li>
              <b>Fabric Signage:</b> While fabric signs are elegant and
              versatile, banners and flags offer the added benefit of movement
              and height, which can enhance visibility.
            </li>
          </ul>

          <h2 className="heading2">
            Our Expertise: Premium Banner & Flag Solutions
          </h2>
          <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
            We specialize in a wide range of banner and flag solutions designed
            to meet your specific needs:
          </p>
          <ul className="my-ul">
            <li>
              <b>Retractable Banner Stands:</b> Perfect for trade shows and
              events, these stands are easy to set up and take down, offering a
              professional and polished display.
            </li>
            <li>
              <b>Teardrop Banners and Flags:</b> Ideal for outdoor advertising,
              these banners are designed to withstand the elements and remain
              highly visible.
            </li>
            <li>
              <b>Vinyl Banners:</b> Durable and versatile, vinyl banners are
              perfect for both indoor and outdoor use, offering vibrant,
              long-lasting graphics.
            </li>
            <li>
              <b>Pillar Wraps:</b> Transform structural pillars into
              eye-catching advertising space with custom-designed wraps that
              enhance your branding.
            </li>
          </ul>
          <p className="text-gray-600 gap-1 mb-3 mt-8 text-justify font-sans">
            Ready to unfurl your brand’s potential with our top-quality banners
            and flags? Contact us today to discover how our innovative solutions
            can elevate your visibility and make a lasting impact on your
            audience!
          </p>
        </div>
      </section>
    </>
  );
};
export default BannersData;
