import { BiChevronDown, BiPhoneCall } from "react-icons/bi";
import logo from "../images/logo.jpg";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { AiFillFacebook, AiOutlineArrowRight } from "react-icons/ai";
import { BsInstagram } from "react-icons/bs";
import { RiLinkedinBoxFill } from "react-icons/ri";

let MyNav = () => {
  let freestanding = [
    "Tenant Pylon Directory Signs",
    "Monument Signs",
    "Billboard Signs",
    "Sidewalk Signs",
    "Parking Lot Signs",
  ];

  let outdoor = [
    "Post & Panel Signs",
    "Ashpalt & Pavement Signs",
    "Fence Signs",
    "Canopy Signs",
    "Traffic & Street Signs",
    "Awning Signs",
    "Facia Signs",
    "Store Front",
    "Yard Signs",
    "Directional Signs",
    "Wayfinding Signage",
    "Marquees",
  ];

  let residential = [
    "House Numbers",
    "Numbers & Lettering",
    "Plaques",
    "Restroom Signs",
    "Room/Door Signs",
    "Accessibility & Braille Signs",
    "Directory Brands",
    "Reception Signs",
    "Safety Signs",
    "Dimensional Signs",
    "Counter Displays",
    "Real Estate Signs",
    "Fence Mesh Signs",
    "Hoarding",
    "Menu Boards",
    "Snapper Frames",
    "POS Signs",
    "Channel Letters",
  ];

  let windows = [
    "Window Lettering",
    "Window Decals",
    "One-Way Vision",
    "Window Frosting",
    "Tints",
  ];

  let vehicles = [
    "Full Body Wraps",
    "Partial Wraps",
    "Magnetic Signs",
    "Fleet Vehicle Wraps",
  ];

  let illuminated = [
    "Digital Signs & Displays",
    "Lightboxes",
    "3D Signs & Lettering",
    "3D Illuminated Letters",
    "LED Backlit Signs",
    "Neon Signs",
  ];
  let fabric = [
    "Straight Tension Fabric Backdrop",
    "Curved Tension Fabric Backdrop",
    "Trade Show/ Fabric Walls",
    "Fabric Hop Up",
  ];
  let banner = [
    "Retractable Banner Stands",
    "Teardrop Banners & Flags",
    "Neon Signs",
    "Vinyl Banners",
  ];
  let decals = [
    "Floor Graphics",
    "Wall Decals and Graphics",
    "Wallpaper",
    "Hoarding Signage",
    "Wall Murals",
    "Stickers & Labels",
    "Vinyl Lettering",
    "Wall Graphics",
    "Perforated"
  ];
  return (
    <>
      <div className="w-full bg-white p-2 z-50 sticky top-0">
        <div className="relative flex items-center flex-col md:flex-row max-w-7xl mx-auto justify-between">
          <ul className="flex gap-4">
            <li className="items-center group">
              <div className="flex items-center font-semibold text-lg cursor-pointer">
                Signage <BiChevronDown />
              </div>
              <ul className="hidden absolute bg-gray-50 shadow-inner px-6 py-5 w-auto group-hover:block p-2 z-50 rounded-md">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                  <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 md:col-span-3 gap-4">
                    <li>
                      <Link to="/freestanding-signs/" className="text-lg font-bold mb-2 hover:text-my-blue">
                        Freestanding Signs
                      </Link>
                      <ul>
                        {freestanding.map((item, index) => (
                          <Link
                            to=""
                            key={index}
                            className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500"
                          >
                            <AiOutlineArrowRight className="text-orange-500"/>
                            {item}
                          </Link>
                        ))}
                      </ul>
                    </li>
                    {/*<li>*/}
                    {/*  <Link to="/vehicles-and-trailers/" className="text-lg font-bold mb-2 hover:text-my-blue">*/}
                    {/*  Vehicles & Trailers*/}
                    {/*  </Link>*/}
                    {/*  <ul>*/}
                    {/*    {vehicles.map((item, index) => (*/}
                    {/*      <Link*/}
                    {/*        to=""*/}
                    {/*        key={index}*/}
                    {/*        className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500"*/}
                    {/*      >*/}
                    {/*        <AiOutlineArrowRight className="text-orange-500"/>{item}*/}
                    {/*      </Link>*/}
                    {/*    ))}*/}
                    {/*  </ul>*/}
                    {/*</li>*/}
                    <li>
                      <Link to="/windows/" className="text-lg font-bold mb-2 hover:text-my-blue">
                        Windows
                      </Link>
                      <ul>
                        {windows.map((item, index) => (
                          <Link
                            to="#"
                            key={index}
                            className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500"
                          >
                            <AiOutlineArrowRight className="text-orange-500"/>{item}
                          </Link>
                        ))}
                      </ul>
                    </li>

                    <li>
                      <Link to="/banner-and-flags/" className="text-lg font-bold mb-2 hover:text-my-blue">
                        Banner & Flags
                      </Link>
                      <ul>
                        {banner.map((item, index) => (
                          <Link
                            to=""
                            key={index}
                            className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500"
                          >
                            <AiOutlineArrowRight  className="text-orange-500"/>{item}
                          </Link>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <Link to="/fabric-and-textile/" className="text-lg font-bold mb-2 hover:text-my-blue">
                        Fabric & Textile
                      </Link>
                      <ul>
                        {fabric.map((item, index) => (
                          <Link
                            to=""
                            key={index}
                            className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500"
                          >
                            <AiOutlineArrowRight  className="text-orange-500"/>{item}
                          </Link>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <Link to="/illuminated-signs/" className="text-lg font-bold mb-2 hover:text-my-blue">
                        Illuminated Signs
                      </Link>
                      <ul>
                        {illuminated.map((item, index) => (
                          <Link
                            to="#"
                            key={index}
                            className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500"
                          >
                            <AiOutlineArrowRight  className="text-orange-500"/>{item}
                          </Link>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <Link to="/decals-and-graphics/" className="text-lg font-bold mb-2 hover:text-my-blue">
                      Decals & Graphics
                      </Link>
                      <ul>
                        {decals.map((item, index) => (
                          <Link
                            to="#"
                            key={index}
                            className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500"
                          >
                            <AiOutlineArrowRight  className="text-orange-500"/>{item}
                          </Link>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <Link to="/outdoor-signs/" className="text-lg font-bold mb-2 hover:text-my-blue">Outdoor Signs</Link>
                      <ul>
                        {outdoor.map((item, index) => (
                          <Link
                            to="#"
                            key={index}
                            className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500"
                          >
                            <AiOutlineArrowRight  className="text-orange-500"/>{item}
                          </Link>
                        ))}
                      </ul>
                    </li>
                  </div>
                  <div className="md:col-span-1 flex flex-col justify-between">
                    <li>
                      <Link to="/residential-and-commercial/" className="text-lg font-bold mb-2 hover:text-my-blue">
                        Residential & Commercial
                      </Link>
                      <ul>
                        {residential.map((item, index) => (
                          <Link
                            to="#"
                            key={index}
                            className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500"
                          >
                            <AiOutlineArrowRight className="text-orange-500" />{item}
                          </Link>
                        ))}
                      </ul>
                    </li>
                  </div>
                </div>
              </ul>
            </li>
            <li className="group items-center font-semibold">
              <div className="flex items-center text-lg cursor-pointer">
                Services <BiChevronDown />
              </div>
              <ul className="hidden bg-gray-200 shadow-inner absolute bg-gray-50 px-6 py-3 space-y-3 w-5xl group-hover:block p-2 rounded-md z-50">
                <li><Link to="" className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500">Installations & Strikes</Link></li>
                <li><Link to="" className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500">Maintenance</Link></li>
                {/*<li><Link to="" className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500">Grafitti Removal</Link></li>*/}
                <li><Link to="" className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500">Printing</Link></li>
                <li><Link to="" className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500">Design</Link></li>
                <li><Link to="" className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500">Builds</Link></li>
              </ul>
            </li>
            {/*<li className="group items-center font-semibold">*/}
            {/*  <div className="flex items-center text-lg cursor-pointer">*/}
            {/*    Rentals <BiChevronDown />*/}
            {/*  </div>*/}
            {/*  <ul className="hidden bg-gray-200 absolute bg-gray-50 shadow-inner px-4 py-3 space-y-2 group-hover:block p-2 rounded-md z-50">*/}
            {/*    /!*<li><Link to="" className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500">Furniture</Link></li>*!/*/}
            {/*    <li><Link to="" className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500">Props</Link></li>*/}
            {/*    <li><Link to="" className="flex items-center gap-2 text-gray-700 block py-1 text-sm hover:text-orange-500">Bar & Tables</Link></li>*/}
            {/*  </ul>*/}
            {/*</li>*/}
          </ul>
          <div className="text-center text-center">
            <Link to="/" className="flex items-center">
              <img src={logo} className="h-20 w-30 mt-2 mb-2 md:mt-0 md:mb-0" alt="Logo" />
            </Link>
          </div>
          <div className="flex gap-2">
            <Link
              to="tel:+14166941996"
              className="flex gap-1 items-center text-black hover:text-orange-500"
            >
              <BiPhoneCall className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-120 duration-300 text-my-blue text-2xl hover:text-orange-500" />
              <div className="hidden lg:block ">+1 (416) 694-1996</div>
            </Link>
            <Link
              to="mailto:sales@clubink.ca"
              className="flex gap-1 items-center text-black hover:text-orange-500"
            >
              <MdEmail className="text-my-blue text-2xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-120 duration-300 hover:text-orange-500" />
              <div className="hidden lg:block ">sales@clubink.ca</div>
            </Link>

            <Link
              to="/get-a-free-quote/"
              className="items-center text-xs font-bold bg-orange-500 px-3 hover:bg-black text-white p-2 rounded "
            >
              GET A QUOTE
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyNav;
