import { AiOutlineArrowRight } from "react-icons/ai";
import img1 from "../images/Fabric-and-Textile.jpg";
import { Link } from "react-router-dom";
let FabricData = () => {
  return (
    <>
      <header className="banner-style">
        <h1 className="banner-heading">
          Fabric & Textile
        </h1>
        <div className="banner-div">
          <Link to="/" className="banner-link">
            Home
          </Link>
          <AiOutlineArrowRight className="banner-icon" />
          <p className="banner-p">Fabric & Textile</p>
        </div>
      </header>
      <section className="service-style">
        <div className="service-div">
          <div className="lg:w-1/2">
            <h2 className="heading2">
              Transform Your Space with Fabric & Textile Signage: The Future of
              Flexible Branding
            </h2>
            <p className="paragraphs">
              In the world of advertising and branding, innovation is key.
              Fabric and textile signage is emerging as a versatile and
              impactful way to convey your message. These soft, durable
              materials offer unique advantages over traditional signage and
              decals, making them an excellent choice for a variety of
              applications. From trade shows to storefronts, fabric and textile
              signs can elevate your brand with style and sophistication.
            </p>
            <p className="paragraphs">
              Let’s explore the benefits and common uses of these flexible
              branding solutions and highlight our cutting-edge offerings that
              include straight tension fabric backdrops, curved tension fabric
              backdrops, trade show fabric walls, fabric hop-ups, and large 3D
              printed inflatables.
            </p>
          </div>
          <div className="lg:w-1/2 lg:mt-0 lg:pl-8 flex justify-center items-center">
            <img
              className="rounded shadow-lg w-3/4"
              src={img1}
              alt="Signage"
            />
          </div>
        </div>
        <div className="max-w-7xl mx-auto">
          <h2 className="heading2">
            The Fabric Advantage: Why Choose Textile Signage?
          </h2>
          <h3 className="heading3">
            1. Versatility and Flexibility
          </h3>
          <ul className="my-ul">
            <li>
              <b>Adaptable Designs:</b> Fabric signs can be printed with
              vibrant, high-resolution graphics, allowing for creative and
              eye-catching designs that stand out.
            </li>
            <li>
              <b>Lightweight and Portable:</b> Easy to transport and set up,
              fabric signs are ideal for events, trade shows, and temporary
              installations.
            </li>
          </ul>
          <h3 className="heading3">
            2. Durability and Longevity
          </h3>
          <ul className="my-ul">
            <li>
              <b>Weather Resistant:</b> High-quality fabric signage is designed
              to withstand various weather conditions, making them suitable for
              both indoor and outdoor use.
            </li>
            <li>
              <b>Washable and Reusable:</b> Unlike many traditional signs,
              fabric signs can be washed and reused, providing a sustainable and
              cost-effective solution.
            </li>
          </ul>
          <h3 className="heading3">3. Eco-Friendly</h3>
          <ul className="my-ul">
            <li>
              <b>Sustainable Materials:</b> Fabric signage often uses
              eco-friendly materials and printing processes, making them a
              greener choice compared to plastic or vinyl signs.
            </li>
            <li>
              <b>Reduced Waste:</b> Their reusability and recyclability
              contribute to lower environmental impact.
            </li>
          </ul>
          <h3 className="heading3">4. Aesthetic Appeal</h3>
          <ul className="my-ul">
            <li>
              <b>Soft and Elegant:</b> Fabric signs offer a smooth,
              sophisticated look that can add a touch of elegance to any
              setting.
            </li>
            <li>
              <b>Seamless Displays:</b> The tension fabric systems provide a
              wrinkle-free, professional appearance, perfect for high-impact
              visual displays.
            </li>
          </ul>

          <h2 className="heading2">
            Common Uses of Fabric and Textile Signage
          </h2>
          <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
            Fabric and textile signage is incredibly versatile and can be used
            in a variety of contexts:
          </p>
          <ul className="my-ul">
            <li>
              <b>Trade Shows and Exhibitions:</b> Create stunning, portable
              displays with straight or curved tension fabric backdrops and
              fabric walls that draw attention to your booth.
            </li>
            <li>
              <b>Retail and Storefronts:</b> Use fabric hop-ups and large 3D
              printed inflatables to create dynamic, eye-catching window
              displays and in-store promotions.
            </li>
            <li>
              <b>Corporate Events and Conferences:</b> Enhance your branding
              with elegant, easy-to-install fabric backdrops that provide a
              professional setting for presentations and meetings.
            </li>
            <li>
              <b>Outdoor Advertising:</b> Durable and weather-resistant, fabric
              signs are perfect for outdoor events, festivals, and promotions.
            </li>
          </ul>

          <h2 className="heading2">
            Fabric & Textile Signage vs. Other Types of Signage and Decals
          </h2>
          <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
            While traditional signage and decals have their uses, fabric and
            textile signs offer unique benefits:
          </p>
          <ul className="my-ul">
            <li>
              <b>Traditional Signage:</b> Often heavier and less portable,
              traditional signs can be cumbersome to transport and install,
              especially for temporary events.
            </li>
            <li>
              <b>Vinyl Decals:</b> While suitable for short-term promotions,
              vinyl decals lack the durability and reusable nature of fabric
              signs, and can often appear less sophisticated.
            </li>
            <li>
              <b>Rigid Signs:</b> These can be difficult to store and transport,
              whereas fabric signs can be folded or rolled up, making them much
              more convenient.
            </li>
          </ul>

          <h2 className="heading2">
            Our Expertise: Elevating Your Brand with Fabric & Textile Solutions
          </h2>
          <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
            We offer a wide range of fabric and textile signage solutions
            designed to meet your specific needs:
          </p>
          <ul className="my-ul">
            <li>
              <b>Straight Tension Fabric Backdrop:</b> Perfect for creating
              clean, professional backgrounds for any event or display.
            </li>
            <li>
              <b>Curved Tension Fabric Backdrop:</b> Add a dynamic touch to your
              booth or display with our elegantly curved fabric backdrops.
            </li>
            <li>
              <b>Trade Show / Fabric Walls:</b> Make a big impact at trade shows
              with large, seamless fabric walls that showcase your brand.
            </li>
            <li>
              <b>Fabric Hop Up:</b> Quick to set up and easy to transport,
              fabric hop-ups are ideal for on-the-go promotions and events.
            </li>
            <li>
              <b>Large 3D Printed Inflatables:</b> Create larger-than-life
              displays that capture attention and leave a lasting impression.
            </li>
          </ul>
          <p className="text-gray-600 gap-1 mb-3 mt-8 text-justify font-sans">
            Ready to transform your space with the elegance and versatility of
            fabric and textile signage? Contact us today to discover how our
            innovative solutions can elevate your brand and make a lasting
            impact!
          </p>
        </div>
      </section>
    </>
  );
};
export default FabricData;
