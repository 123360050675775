import { AiOutlineArrowRight } from "react-icons/ai";
import img1 from "../images/Illuminated-Signs-2.jpg";
import { Link } from "react-router-dom";
let IlluminatedData = () => {
    return (
        <>
            <header className="banner-style">
                <h1 className='banner-heading'>Illuminated Signs</h1>
                <div className="banner-div">
                    <Link to="/" className="banner-link">Home</Link>
                    <AiOutlineArrowRight className="banner-icon" />
                    <p className="banner-p">Illuminated Signs</p>
                </div>
            </header>
            <section className="service-style">
                <div className="service-div">
                    <div className="lg:w-1/2">
                        <h2 className="heading2">Illuminate Your Brand: The Power and Versatility of Illuminated Signs</h2>
                        <p className="paragraphs">
                        In today’s competitive market, capturing attention is crucial. Whether you run a small local business or a large corporation, your signage is often the first impression you make on potential customers. One of the most effective ways to stand out is through illuminated signs.
                        </p>
                        <p className="paragraphs">
                        These glowing beacons not only draw the eye but also communicate professionalism and reliability. Let’s dive into the advantages and common uses of illuminated signs compared to other types of signage and decals, and explore how our cutting-edge solutions can elevate your brand.
                        </p>
                    </div>
                    <div className="lg:w-1/2 lg:mt-0 lg:pl-8 flex justify-center items-center">
                        <img className="rounded shadow-lg w-3/4" src={img1} alt="Signage" />
                    </div>
                </div>
                <div className="max-w-7xl mx-auto">
                    <h2 className="heading2">The Glow-Up: Advantages of Illuminated Signs</h2>
                    <h3 className="heading3">1. Visibility Day and Night</h3>
                    <ul className="my-ul">
                        <li><b>24/7 Attention:</b> Illuminated signs ensure your business is visible at all times, unlike traditional signs that rely on daylight. This constant visibility can attract both foot and vehicular traffic even after sunset.</li>
                        <li><b>Enhanced Readability:</b> Bright, well-lit signs are easier to read from a distance, helping potential customers find you quickly and easily.</li>
                    </ul>
                    <h3 className="heading3">2. Attractiveness and Appeal</h3>
                    <ul className="my-ul">
                        <li><b>Eye-Catching:</b> The human eye is naturally drawn to light, making illuminated signs more noticeable and memorable.</li>
                        <li><b>Modern Aesthetic:</b> Illuminated signs convey a contemporary, professional image that can enhance your brand's identity.</li>
                    </ul>
                    <h3 className="heading3">3. Versatility</h3>
                    <ul className="my-ul">
                        <li><b>Customizable:</b> With various styles such as digital displays, lightboxes, 3D signs, and neon signs, illuminated signage can be tailored to fit any brand personality and message.</li>
                        <li><b>Dynamic Content:</b> Digital signs allow for real-time updates and animations, providing dynamic content that can engage and inform customers more effectively.</li>
                    </ul>
                    <h3 className="heading3">4. Durability and Longevity</h3>
                    <ul className="my-ul">
                        <li><b>Weather Resistant:</b> High-quality illuminated signs are designed to withstand the elements, ensuring they stay bright and attractive for years.</li>
                        <li><b>Energy Efficient:</b> With the advent of LED technology, illuminated signs are now more energy-efficient, offering long-term savings on electricity bills.</li>
                    </ul>
                    

                    <h2 className="heading2">Common Uses of Illuminated Signs</h2>
                    <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
                    Illuminated signs are versatile and can be used in various settings to enhance visibility and appeal:
                    </p>
                    <ul className="my-ul">
                        <li><b>Storefronts and Retail Shops:</b> Attract customers with vibrant signs that highlight your brand and products.</li>
                        <li><b>Restaurants and Cafes:</b> Create a welcoming ambiance with warm, inviting neon or LED signs.</li>
                        <li><b>Corporate Offices:</b> Enhance your professional image with sleek, modern lightboxes and 3D illuminated lettering.</li>
                        <li><b>Events and Exhibitions:</b> Stand out in crowded spaces with dynamic digital displays and eye-catching 3D signs.</li>
                        <li><b>Hotels and Hospitality: </b> Guide guests with clear, well-lit signage that enhances their experience.</li>

                    </ul>

                    <h2 className="heading2">Illuminated Signs vs. Other Types of Signage and Decals</h2>
                    <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
                    While traditional signage and decals have their place, illuminated signs offer distinct advantages:
                    </p>
                    <ul className="my-ul">
                    <li><b>Traditional Signage:</b> Often limited by lighting conditions, traditional signs can be harder to see at night and less impactful.</li>
                    <li><b>Decals:</b> Typically used for smaller, less permanent displays, decals are great for windows and interiors but lack the visibility and durability of illuminated signs.</li>
                    <li><b>Non-Illuminated 3D Signs:</b> These offer depth and dimension but lose their impact in low-light conditions.</li>
                    </ul>

                    <h2 className="heading2">Our Expertise: A Bright Solution for Every Need</h2>
                    <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
                    We specialize in a wide range of illuminated sign solutions to meet your unique requirements:
                    </p>
                    <ul className="my-ul">
                    <li><b>Digital Signs & Displays:</b> Engage customers with interactive and updateable content.</li>
                    <li><b>Lightboxes:</b> Create a sleek and professional look with backlit graphics.</li>
                    <li><b>3D Signs & Lettering:</b> Add depth and dimension to your branding.</li>
                    <li><b>3D Illuminated Letters:</b> Combine the impact of 3D signs with the visibility of illumination.</li>
                    <li><b>LED Backlit Signs:</b> Enjoy energy-efficient, bright signage that lasts.</li>
                    <li><b>Neon Signs:</b> Bring a retro charm and vibrant glow to your business.</li>
                    </ul>
                    <p className="text-gray-600 gap-1 mb-3 mt-8 text-justify font-sans">
                    Illuminate your brand with our state-of-the-art signage solutions. Contact us today to discover how we can make your business shine brighter than ever!
                    </p>
                </div>
            </section>
        </>
    );
}
export default IlluminatedData;