import FabricData from "./Components/fabric_signs";
import Footer from "./Components/footer";
import MyNav from "./Components/mynavbar";

let Fabric = ()=>{
    return(
        <>
        <MyNav/>
        <FabricData/>
        <Footer/>
        </>
    );
}
export default Fabric;