import { AiOutlineArrowRight } from "react-icons/ai";
import img1 from "../images/Windows-1.jpg";
import { Link } from "react-router-dom";
let WindowsData = () => {
    return (
        <>
            <header className="banner-style">
                <h1 className='banner-heading'>Windows</h1>
                <div className="banner-div">
                    <Link to="/" className="banner-link">Home </Link>
                    <AiOutlineArrowRight className="banner-icon" />
                    <p className="banner-p">Windows</p>
                </div>
            </header>
            <section className="service-style">
                <div className="service-div">
                    <div className="lg:w-1/2">
                        <h2 className="heading2">Transform Your Space with Stunning Window Graphics</h2>
                        <p className="paragraphs">
                        In the bustling world of advertising and branding, businesses are constantly on the lookout for innovative ways to capture attention and convey their message. Enter window graphics—a dynamic and versatile solution that not only enhances the aesthetic appeal of your space but also serves a multitude of practical purposes.
                        </p>
                        <p className="paragraphs">
                        Whether you’re looking to boost your storefront’s curb appeal, provide privacy, or communicate essential information, window graphics offer a world of possibilities.
                        </p>
                    </div>
                    <div className="lg:w-1/2 lg:mt-0 lg:pl-8 flex justify-center items-center">
                        <img className="rounded shadow-lg w-3/4" src={img1} alt="Signage" />
                    </div>
                </div>
                <div className="max-w-7xl mx-auto">
                    <h2 className="heading2">The Advantages of Window Graphics</h2>
                    <h3 className="heading3">1. Enhanced Visibility and Brand Awareness</h3>
                    <p className="items-center text-gray-600 gap-1 mb-3 text-justify font-sans space-y-3">
                    Window graphics are a powerful tool for grabbing the attention of passersby. With eye-catching designs and vibrant colors, your storefront can become a visual magnet. This increased visibility helps to reinforce your brand and make a lasting impression.
                    </p>
                    <h3 className="heading3">2. Cost-Effective Advertising</h3>
                    <p className="items-center text-gray-600 gap-1 mb-3 text-justify font-sans space-y-3">
                    Compared to other forms of advertising, window graphics provide a high return on investment. They transform unused window space into a billboard for your business, delivering your message 24/7 without recurring costs.
                    </p>
                    <h3 className="heading3">3. Privacy and Security</h3>
                    <p className="items-center text-gray-600 gap-1 mb-3 text-justify font-sans space-y-3">
                    Frosted window graphics and tints offer privacy for your employees and customers while still allowing natural light to filter through. They also add a layer of security by obscuring the view into your premises, which can be particularly beneficial for offices and healthcare facilities.
                    </p>
                    <h3 className="heading3">4. Versatility and Customization</h3>
                    <p className="items-center text-gray-600 gap-1 mb-3 text-justify font-sans space-y-3">
                    Window graphics can be customized to fit any size or shape, making them suitable for a variety of applications. From small decals to full-window displays, the possibilities are endless.
                    </p>
                    <h3 className="heading3">5. Easy Installation and Removal</h3>
                    <p className="items-center text-gray-600 gap-1 mb-3 text-justify font-sans space-y-3">
                    Unlike permanent signage, window graphics are relatively easy to install and remove. This makes them an ideal choice for seasonal promotions, special events, and temporary branding needs.
                    </p>

                    <h2 className="heading2">Common Uses of Window Graphics</h2>
                    <ul className="my-ul">
                        <li><b>Retail Stores:</b> Promote sales, display store hours, or highlight new products with vibrant window graphics that draw customers inside.</li>
                        <li><b>Offices:</b> Use frosted or tinted graphics to create a professional look while maintaining privacy in meeting rooms and individual offices.</li>
                        <li><b>Restaurants and Cafes:</b> Showcase your menu, promote daily specials, or simply enhance the ambiance with artistic window designs.</li>
                        <li><b>Healthcare Facilities:</b> Provide necessary information, such as office hours and services, while ensuring patient privacy with frosted glass treatments.</li>
                        <li><b>Gyms and Fitness Centers:</b> Inspire and motivate clients with energetic and bold window graphics that reflect the spirit of your brand.</li>
                    </ul>


                    <h2 className="heading2">Comparing Window Graphics to Other Types of Signage and Decals</h2>
                    <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
                    While traditional signage such as banners and billboards have their place, window graphics offer unique benefits that set them apart. Unlike banners, which can be obstructive and require additional hardware, window graphics utilize existing window space without blocking views or light. They also offer more flexibility compared to rigid signs, which can be cumbersome and limited in placement options.
                    </p>

                    <h2 className="heading2">Our Window Graphic Solutions</h2>
                    <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
                    At Club Ink, we specialize in a variety of window graphic solutions to meet your specific needs:
                    </p>
                    <ul className="my-ul">
                        <li><b>Window Lettering:</b> Perfect for displaying business names, logos, and contact information in a clean and professional manner.</li>
                        <li><b>Decals:</b> Versatile and easy to apply, decals are great for promotions, logos, and decorative elements.</li>
                        <li><b>One-Way Vision:</b> These graphics allow people inside to see out while blocking the view from the outside, making them ideal for privacy and advertising.</li>
                        <li><b>Frosting:</b> Add a touch of elegance and privacy to your windows with frosted graphics that diffuse light beautifully.</li>
                        <li><b>Tints:</b> Control sunlight and reduce glare while adding a sleek, modern look to your windows.</li>
                    </ul>
                    <p className="text-gray-600 gap-1 mb-3 mt-8 text-justify font-sans">
                    Whether you’re looking to transform your storefront, office, or any other space, window graphics offer a creative and effective solution. Contact us today to discover how we can help you make a striking visual impact with our high-quality window lettering, decals, one-way vision graphics, frosting, and tints.
                    </p>
                    <p className="text-gray-600 gap-1 mb-3 mt-8 text-justify font-sans">
                    Feel free to reach out to our expert team for a consultation and let us help you bring your vision to life!
                    </p>
                </div>
            </section>
        </>
    );
}
export default WindowsData;