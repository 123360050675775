import BannersData from "./Components/banner_signs";
import Footer from "./Components/footer";
import MyNav from "./Components/mynavbar";

let Banner = ()=>{
    return(
        <>
        <MyNav/>
        <BannersData/>
        <Footer/>
        </>
    );
}
export default Banner;