import Footer from "./Components/footer";
import WindowsData from "./Components/windows_sign";
import MyNav from "./Components/mynavbar";

let Windows = ()=>{
    return(
        <>
        <MyNav/>
        <WindowsData/>
        <Footer/>
        </>
    );
}
export default Windows;