import Services from "./Components/services";
import Footer from "./Components/footer";
import Welcome from "./Components/welcomewhatwedo";
import ImageSlider from "./Components/imageslider";
import MyNav from "./Components/mynavbar";

let HomeScreen = () =>{
    return(
        <>
        <MyNav/>
        <ImageSlider/>
        <Welcome/>
        <Services/>
        <Footer/>
        </>
    );
}
export default HomeScreen;