import { AiOutlineArrowRight } from "react-icons/ai";
import img1 from "../images/Vehicles-and-Trailers.jpg";
import { Link } from "react-router-dom";
let VehiclesData = () => {
    return (
        <>
            <header className="banner-style">
                <h1 className='banner-heading'>Vehicles & Trailers</h1>
                <div className="banner-div">
                    <Link to="/" className="banner-link">Home </Link>
                    <AiOutlineArrowRight className="banner-icon" />
                    <p className="banner-p">Vehicles & Trailers</p>
                </div>
            </header>
            <section className="service-style">
                <div className="service-div">
                    <div className="lg:w-1/2">
                        <h2 className="heading2">Transform Your Brand Visibility with Vehicle & Trailer Wraps, Graphics, and Signs</h2>
                        <p className="paragraphs">
                        In today’s competitive market, standing out is not just an advantage, it’s a necessity. One of the most innovative and effective ways to elevate your brand’s visibility is through vehicle and trailer wraps, graphics, and signs.
                        </p>
                        <p className="paragraphs">
                        Whether you’re cruising down the highway or parked at an event, a well-designed wrap can turn your vehicle into a powerful marketing tool. Let’s dive into the world of vehicle wraps and discover why they’re a game-changer for businesses of all sizes.
                        </p>
                    </div>
                    <div className="lg:w-1/2 lg:mt-0 lg:pl-8 flex justify-center items-center">
                        <img className="rounded shadow-lg w-3/4" src={img1} alt="Signage" />
                    </div>
                </div>
                <div className="max-w-7xl mx-auto">
                    <h2 className="heading2">The Power of Vehicle Wraps</h2>
                    <p className="items-center text-gray-600 gap-1 mb-3 text-justify font-sans space-y-3">
                    Vehicle wraps are large vinyl graphics or decals applied directly over a vehicle’s original paint. This technique can completely transform the look of your car, van, truck, or trailer. Here’s why they’re so effective:
                    </p>
                    <h3 className="heading3">1. High Visibility:</h3>
                    <p className="items-center text-gray-600 gap-1 mb-3 text-justify font-sans space-y-3">
                    Unlike traditional billboards or stationary signs, vehicle wraps travel wherever you go, exposing your brand to thousands of potential customers daily. A single vehicle wrap can generate between 30,000 to 70,000 impressions per day!
                    </p>
                    <h3 className="heading3">2. Cost-Effective Advertising</h3>
                    <p className="items-center text-gray-600 gap-1 mb-3 text-justify font-sans space-y-3">
                    Compared to other forms of advertising like TV, radio, or print, vehicle wraps offer a one-time investment for continuous, long-term exposure. The average cost per impression for a vehicle wrap is significantly lower than other advertising mediums.
                    </p>
                    <h3 className="heading3">3. Customizable and Versatile</h3>
                    <p className="items-center text-gray-600 gap-1 mb-3 text-justify font-sans space-y-3">Whether you need a full body wrap, partial wrap, or simple magnetic signs, the options are endless. You can tailor the design to suit your brand’s identity, ensuring your message is conveyed exactly as you envision.Frosted window graphics and tints offer privacy for your employees and customers while still allowing natural light to filter through. They also add a layer of security by obscuring the view into your premises, which can be particularly beneficial for offices and healthcare facilities.
                    </p>
                    <h3 className="heading3">4. Durable and Protective</h3>
                    <p className="items-center text-gray-600 gap-1 mb-3 text-justify font-sans space-y-3">
                    High-quality vinyl wraps not only serve as moving billboards but also protect your vehicle’s paint from sun damage, minor abrasions, and weather elements, thus maintaining the vehicle's resale value.
                    </p>

                    <h2 className="heading2">Common Uses of Vehicle Wraps, Graphics, and Signs</h2>
                    <ul className="my-ul">
                    <li><b>Full Body Wraps:</b> For maximum impact, full body wraps cover the entire surface of your vehicle. They are perfect for creating a bold, eye-catching statement and ensuring your brand is noticed from every angle.</li>
                    <li><b>Partial Wraps:</b> Ideal for businesses on a budget, partial wraps cover a portion of the vehicle, strategically designed to capture attention without the full expense of a complete wrap. This can be a logo on the hood, branding on the sides, or graphics on the rear.</li>
                    <li><b>Magnetic Signs:</b> These are a fantastic option for businesses that require flexibility. Magnetic signs can be easily attached and removed from any metal surface on your vehicle, making them perfect for temporary promotions or when using personal vehicles for business purposes.</li>
                    <li><b>Fleet Vehicle Wraps:</b> For businesses with multiple vehicles, fleet wraps ensure uniformity across all company cars, vans, or trucks. This consistent branding boosts professional appearance and brand recognition across a wide area.</li>
                    </ul>

                    <h2 className="heading2">Why Choose Wraps Over Traditional Signage?</h2>
                    <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
                    While traditional signage like banners, billboards, and posters have their place, vehicle wraps offer unparalleled advantages:
                    </p>
                    <ul className="my-ul">
                    <li><b>Mobility:</b> Unlike fixed signs, vehicle wraps move with you, constantly reaching new audiences and creating more touchpoints with potential customers.</li>
                    <li><b>High Impact:</b> A well-designed wrap can turn heads and make a lasting impression, much more effectively than a stationary sign.</li>
                    <li><b>Long Lifespan:</b> With proper care, vehicle wraps can last for several years, providing long-term advertising without the recurring costs associated with other forms of media.</li>
                    <li><b>Versatility:</b> Wraps can be updated or replaced as needed, allowing you to change your message or refresh your design without a major overhaul.</li>
                    </ul>

                    <h2 className="heading2">Why Choose Us?</h2>
                    <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
                    At Club Ink, we specialize in creating stunning vehicle and trailer wraps, graphics, and signs that make your brand stand out. Our services include:
                    </p>
                    <ul className="my-ul">
                    <li><b>Full Body Wraps:</b> Transform your vehicle into a dynamic advertising machine.</li>
                    <li><b>Partial Wraps:</b> Capture attention without breaking the bank.</li>
                    <li><b>Magnetic Signs:</b> Enjoy the flexibility of removable branding.</li>
                    <li><b>Fleet Vehicle Wraps:</b> Ensure your entire fleet projects a professional, cohesive image.</li>
                    </ul>
                    <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
                    Our experienced team uses high-quality materials and cutting-edge techniques to deliver wraps that are not only visually striking but also durable and long-lasting. We work closely with you from concept to completion, ensuring your vision becomes a reality.
                    </p>

                    <h2 className="heading2">Ready to Turn Heads?</h2>
                    <p className="text-gray-600 gap-1 mb-3 text-justify font-sans">
                    Don’t let your vehicle blend into the background. Make a statement and drive your brand forward with our expert wrap services. Contact us today to learn more about how we can help you maximize your marketing potential and reach new heights of brand visibility.
                    </p>
                </div>
            </section>
        </>
    );
}
export default VehiclesData;