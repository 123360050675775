import { AiOutlineArrowRight } from "react-icons/ai";
import img1 from "../images/Freestanding-Signs-2.jpg";
import { Link } from "react-router-dom";
let FreeStandingData = () => {
    return (
        <>
            <header className="banner-style">
                <h1 className='banner-heading'>Freestanding Signs</h1>
                <div className="banner-div">
                    <Link to="/" className="banner-link">Home </Link>
                    <AiOutlineArrowRight className="banner-icon" />
                    <p className="banner-p">Freestanding Signs</p>
                </div>
            </header>
            <section className="service-style">
                <div className="service-div">
                    <div className="lg:w-1/2">
                        <h2 className="heading2">The Power of Freestanding Signs: Why They're a Game-Changer for Your Business</h2>
                        <p className="paragraphs">
                            In the bustling world of advertising, visibility is key. One of the most effective ways to grab attention and direct customers to your business is through freestanding signs. These standalone structures are not only eye-catching but also versatile, making them a top choice for many businesses. But what exactly sets them apart from other types of signage, and why should you consider them for your advertising needs? Let’s dive in.
                        </p>
                        <h2 className="heading2">What Are Freestanding Signs?</h2>
                        <p className="paragraphs">
                            Freestanding signs, also known as monument signs or pylon signs, are standalone structures typically mounted on a solid base, often made of concrete. Unlike wall-mounted signs, they are placed away from buildings and can be positioned in strategic locations to maximize visibility.
                        </p>
                    </div>
                    <div className="lg:w-1/2 lg:mt-0 lg:pl-8 flex justify-center items-center">
                        <img className="rounded shadow-lg w-3/4" src={img1} alt="Signage" />
                    </div>
                </div>
            </section>
        </>
    );
}
export default FreeStandingData;