import Footer from "./Components/footer";
import MyNav from "./Components/mynavbar";
import RequestMessage from "./Components/requestmessage";

let GetQuote = () =>{
    return(
        <>
        <MyNav/>
        <RequestMessage/>
        <Footer/>
        </>
    );
}
export default GetQuote;