import DecalsGData from "./Components/decalsgraphics";
import Footer from "./Components/footer";
import MyNav from "./Components/mynavbar";

let Decals = ()=>{
    return(
        <>
        <MyNav/>
        <DecalsGData/>
        <Footer/>
        </>
    );
}
export default Decals;