import standingimg from "../images/standing.jpg";

const Welcome = () => {
    return (
        <section className="py-6 px-4">
            <div className="max-w-7xl mx-auto pt-6 flex flex-col lg:flex-row items-center">
                <div className="lg:w-5/6">
                    <div className="relative">
                        <h1 className="text-5xl lg:text-8xl font-bold text-gray-200">Welcome</h1>
                        <h2 className="absolute bottom-0 left-0 text-sm lg:text-base font-bold">WHAT WE DO</h2>
                    </div>
                    <h2 className="text-2xl md:text-4xl font-bold mt-4 mb-4">Builds. Prints. Design. <span className="text-amber-500">Your Vision Brought to Life.</span></h2>
                    <hr className="w-20 h-1 mt-1 mb-4 bg-amber-500 border-0" />
                    <p className="paragraphs">
                        Welcome to Club Ink, your premier destination for cutting-edge digital printing and signage services since 1996.
                    </p>
                    <p className="paragraphs">
                        At Club Ink, we specialize in bringing your vision to life with unparalleled customization options. With a dedicated focus on the film industry, events and tradeshows, and municipal sectors, we understand the importance of making a statement that resonates. Whether you’re looking to promote your latest film, create immersive event experiences, or enhance urban landscapes with vibrant signage, our expert team is here to exceed your expectations. From concept to completion, Club Ink is your partner in transforming ideas into captivating realities.
                    </p>
                    <p className="paragraphs">
                        Contact us today to elevate your brand presence with our tailored digital printing and signage solutions.
                    </p>
                </div>
                <div className="lg:w-auto flex justify-end items-center lg:pl-8">
                    <img className="rounded-xl shadow-lg h-full w-full animate-float" src={standingimg} alt="Signage" />
                </div>
            </div>
        </section>
    );
};

export default Welcome;
