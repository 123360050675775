import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import slide1 from "../images/slide-1.jpg";
import slide2 from "../images/slide-2.jpg";
import slide3 from "../images/slide-3.jpg";
import slide4 from "../images/slide-4.jpg";
import img1 from "../images/image.jpg";
import { BiMobile } from "react-icons/bi";
import { Link } from "react-router-dom";

const ImageSlider = () => {
  return (
    <>
      <Splide
        options={{
          type: "loop",
          autoplay: true,
          perPage: 1,
          interval: 3000,
          height: "500px",
          speed: 2000,
          easing: "ease-in-out",
        }}
        aria-label="My Favorite Images"
      >
        <SplideSlide>
          <div className="relative w-full h-full">
            <img
              className="object-cover w-full h-full"
              src={slide1}
              alt="Slide1"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
              <span className="text-white font-bold text-lg md:text-3xl">Professional, Creative, and Flexible</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="relative w-full h-full">
            <img
              className="object-cover w-full h-full"
              src={slide2}
              alt="Slide2"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
              <span className="text-white font-bold text-lg md:text-3xl">A Team That Cares About You!</span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="relative w-full h-full">
            <img
              className="object-cover w-full h-full"
              src={slide3}
              alt="Slide3"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
              <span className="text-white font-bold text-lg md:text-3xl"></span>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="relative w-full h-full">
            <img
              className="object-cover w-full h-full"
              src={slide4}
              alt="Slide4"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
              <span className="text-white font-bold text-lg md:text-3xl"></span>
            </div>
          </div>
        </SplideSlide>
      </Splide>
      <div className="px-4 md:px-8 lg:px-16 py-2">
        <div className="max-w-7xl mx-auto flex flex-col items-center md:flex-row md:items-end md:justify-between gap-3">
          <div className="flex flex-col items-center md:flex-row md:items-end gap-3">
            <img
              className="w-56 z-30 -mt-20 rounded-xl overflow-hidden"
              src={img1}
              alt="PIC"
            />
            <div className="flex flex-col items-center md:items-start text-center md:text-left mt-5">
              <Link to="tel:+4166941996" className="flex items-center gap-2">
                <BiMobile className="text-my-blue text-5xl hover:text-amber-500 transition ease-in-out duration-150 transform hover:-translate-y-1 hover:scale-110" />
                <div className="font-semibold text-lg md:text-xl lg:text-3xl">
                  +1 (416) 694-1996
                </div>
              </Link>
              <div className="font-semibold text-sm md:text-base lg:text-2xl">
                Got an idea? Call Now.
              </div>
            </div>
          </div>
          <div className="pb-2 mt-4 md:mt-0">
            <Link
              to="mailto:sales@clubink.ca"
              className="bg-orange-500 font-semibold hover:bg-black text-white py-2 px-4 rounded transition duration-150"
            >
              Contact Us!
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageSlider;
