import Footer from "./Components/footer";
import MyNav from "./Components/mynavbar";
import ResidentialData from "./Components/residential_com";

let Residential = ()=>{
    return(
        <>
        <MyNav/>
        <ResidentialData/>
        <Footer/>
        </>
    );
}
export default Residential;