import {BrowserRouter,Routes,Route} from 'react-router-dom';
import './index.css'
import HomeScreen from './homescreen';
import GetQuote from './get_a_quote';
import FreeStanding from './free_standing_sign';
import OutdoorSigns from './outdoor_signs';
import Residential from './residentialcommercial';
import Windows from './windows';
import Decals from './decals';
import Vehicles from './vehicles';
import Illuminated from './illuminated';
import Banner from './banner';
import Fabric from './fabric';
import ScrollToTop from './ScrollWith';
import RealEstate from "./real_estate";
function App() {
  return (
    <>
    <BrowserRouter>
    <ScrollToTop/>
      <Routes>
        <Route path={"/"} element={<HomeScreen/>}></Route>
        <Route path={"/get-a-free-quote/"} element={<GetQuote/>}></Route>
        <Route path={"/freestanding-signs/"} element={<FreeStanding/>}></Route>
        <Route path={"/real-estate/"} element={<RealEstate/>}></Route>
        <Route path={"/outdoor-signs/"} element={<OutdoorSigns/>}></Route>
        <Route path={"/residential-and-commercial/"} element={<Residential/>}></Route>
        <Route path={"/windows/"} element={<Windows/>}></Route>
        <Route path={"/decals-and-graphics/"} element={<Decals/>}></Route>
        <Route path={"/vehicles-and-trailers/"} element={<Vehicles/>}></Route>
        <Route path={"/illuminated-signs/"} element={<Illuminated/>}></Route>
        <Route path={"/banner-and-flags/"} element={<Banner/>}></Route>
        <Route path={"/fabric-and-textile/"} element={<Fabric/>}></Route>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
