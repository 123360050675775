import img1 from '../images/FreestandingSigns.jpg';
import img2 from '../images/OutdoorSigns.jpg';
import img3 from '../images/ResidentialCommercial.jpg';
import img4 from '../images/Windows.jpg';
import img5 from '../images/DecalsGraphics.jpg';
import img6 from '../images/VehiclesTrailers.jpg';
import img7 from '../images/IlluminatedSigns.jpg';
import img8 from '../images/bannersFlags.jpg';
import img9 from '../images/FabricTextile.jpg';
import img10 from '../images/DisplayCases.jpg';
import img11 from '../images/realestate.jpg';
import { BiRightArrowCircle } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const Services = () => {
    let data = [{
        img: img1,
        title: 'Freestanding Signs',
        link: '/freestanding-signs/'
    }, {
        img: img2,
        title: 'Outdoor Signs',
        link: '/outdoor-signs/'
    }, {
        img: img3,
        title: 'Residential & Commercial',
        link: '/residential-and-commercial/'
    }, {
        img: img4,
        title: 'Windows',
        link: '/windows/'
    }, {
        img: img5,
        title: 'Decals & Graphics',
        link: '/decals-and-graphics/'
    }, {
        img: img6,
        title: 'Vehicles & Trailers',
        link: '/vehicles-and-trailers/'
    }, {
        img: img7,
        title: 'Illuminated Signs',
        link: '/illuminated-signs/'
    }, {
        img: img8,
        title: 'Banner & Flags',
        link: '/banner-and-flags/'
    }, {
        img: img9,
        title: 'Fabric & Textile',
        link: '/fabric-and-textile/'
    }, {
        img: img10,
        title: 'Display Cases',
    }, {
        img: img11,
        title: 'Real Estate',
        link: '/real-estate/'
    }];
    return (
        <div className=''>
            <div className='max-w-7xl mx-auto bg-cover'>
            <div className='pt-20 text-center'>
                <p className='text-base'>WORK FLOW</p>
                <h1 className='mt-1 text-2xl md:text-4xl font-bold'>Best Services For Signage</h1>
                <hr className="w-20 h-1 mx-auto mt-5 mb-1 bg-amber-500 border-0" />
            </div>
            <div className="p-10 pt-4 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
                {data.map((item, index) => (
                    <div key={index} className="relative overflow-hidden rounded-xl shadow-lg hover:text-orange-500">
                        <img className="w-full h-full hover:scale-125 transition-transform duration-500" src={item.img} alt={item.title} />
                        <div className="absolute rounded-t-lg bottom-0 space-y-3 w-full bg-white px-4 py-5 text-center">
                            <div className="font-bold text-xl mb-2">{item.title}</div>
                            <Link to={item.link} className="inline-block bg-my-blue hover:bg-orange-500 text-white font-semi-bold py-2 px-5 rounded-lg">Read More</Link>
                        </div>
                    </div>
                ))}
                <div className="bg-amber-500 text-gray-100 rounded-xl overflow-hidden shadow-lg px-2 py-3 text-center">
                    <h1 className='font-semibold text-xl mb-3 mt-1'>We Provide Best <br /> Services for Signage.</h1>
                    <p className='text-base mb-3 mt-2'>Whether you’re after a little design help or a reliable set of hands for a complex national project, it’s easy with our design & service team looking after you.</p>
                    <Link to="mailto:sales@clubink.ca" className="inline-block inline-flex items-center gap-2 bg-transparent px-4 mt-1 hover:bg-white hover:text-black border-2 border border-white font-semi-bold p-2 rounded-full justify-center">
                        CONTACT US <BiRightArrowCircle />
                    </Link>
                </div>
            </div>
        </div>
        </div>
    );
}
export default Services;
