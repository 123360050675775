import Footer from "./Components/footer";
import FreeStandingData from "./Components/freestanding";
import MyNav from "./Components/mynavbar";

let FreeStanding = ()=>{
    return(
        <>
        <MyNav/>
        <FreeStandingData/>
        <Footer/>
        </>
    );
}
export default FreeStanding;