import MyNav from "./Components/mynavbar";
import Footer from "./Components/footer";
import {Link} from "react-router-dom";
import {AiOutlineArrowRight} from "react-icons/ai";
import img1 from "./images/Freestanding-Signs-2.jpg";


export default function RealEstate(){
    return (
        <>
            <MyNav/>
            <header className="banner-style">
                <h1 className='banner-heading'>Real Estate</h1>
                <div className="banner-div">
                    <Link to="/" className="banner-link">Home </Link>
                    <AiOutlineArrowRight className="banner-icon"/>
                    <p className="banner-p">Real Estate</p>
                </div>
            </header>
            <section className="service-style">
                <div className="service-div">
                    <div className="w-full leading-9 text-lg">

                        <p className="paragraphs">
                            At Club Ink, we understand the critical role that real estate signs play in attracting potential buyers and showcasing properties effectively. Our expertise in creating high-quality real estate signs ensures that we can meet all your needs, regardless of size or shape. Whether you require standard dimensions or custom designs, our advanced printing technology and durable materials guarantee that your signs will stand out and withstand the elements. From grommets and rounded corners to H-frames and hanging clips, we offer a range of mounting hardware and accessories to ensure seamless installation and a professional appearance. Trust Club Ink to deliver exceptional real estate signs that help you make a lasting impression.
                        </p>

                    </div>

                </div>
            </section>
            <Footer/>
        </>
    );
}
