import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

let RequestMessage = () => {
    let { register, handleSubmit, formState: { errors }, reset } = useForm();

    function submitForm(data){
        reset();
        // let{username, email, phone_number, business_name, business_address,product_type , upload_file, messages}=data;
        // var formdata = new FormData();
        // formdata.append("username", username);
        // formdata.append("email", email);
        // formdata.append("phone_number",phone_number);
        // formdata.append("business_name", business_name);
        // formdata.append("business_address",business_address);
        // formdata.append("product_type",product_type);
        // formdata.append("upload_file", upload_file[0]);
        // formdata.append("messages",messages);

        // axios.post("http://localhost:3300/register-quote/",formdata).then(response=>{
        //     if(response.data === "successfull"){
        //         Swal.fire({
        //             position: "center",
        //             icon: "success",
        //             title: "Message Sent",
        //             showConfirmButton: false,
        //             timer: 1500
        //           });
        //           reset();
        //     }
        // })
    }

    return (
        <>
        <header className="banner-style">
        <h1 className="banner-heading">Get a free quote</h1>
        <div className="banner-div">
          <Link to="/" className="banner-link">
            Home
          </Link>
          <AiOutlineArrowRight className="banner-icon" />
          <p className="banner-p">Get a free quote</p>
        </div>
      </header>
            <div className="max-w-7xl mx-auto p-12">
                <h3 className="mt-2 text-base text-center">MESSAGE US</h3>
                <h2 className="mt-1 text-center text-4xl font-bold font-sans">Request A Quote</h2>
                <div className="flex justify-center">
                    <form 
                        className="bg-white shadow-xl rounded px-8 pt-6 pb-8 mb-4 w-full max-w-xl" 
                        onSubmit={handleSubmit(submitForm)} 
                        autoComplete="off"
                    >
                        <div className="mb-4">
                            <label className="flex gap-1 text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                                Your Name <span className="text-red-600">*</span>
                            </label>
                            <input 
                                {...register('username', { required: "Your Name is required" })} 
                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                                id="username" 
                                type="text" 
                                placeholder="Your Name" 
                            />
                            <ErrorMessage 
                                name="username" 
                                errors={errors} 
                                render={({ message }) => <p className="text-red-600">{message}</p>} 
                            />
                        </div>
                        <div className="mb-4">
                            <label className="flex gap-1 text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                Email <span className="text-red-600">*</span>
                            </label>
                            <input 
                                {...register('email', { 
                                    required: "Email is required", 
                                    pattern: { 
                                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, 
                                        message: "Invalid email address" 
                                    }
                                })} 
                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                                id="email" 
                                type="email" 
                                placeholder="Email" 
                            />
                            <ErrorMessage 
                                name="email" 
                                errors={errors} 
                                render={({ message }) => <p className="text-red-600">{message}</p>} 
                            />
                        </div>
                        <div className="mb-4">
                            <label className="flex gap-1 text-gray-700 text-sm font-bold mb-2" htmlFor="phone_number">
                                Phone Number <span className="text-red-600">*</span>
                            </label>
                            <input 
                                {...register('phone_number', { required: "Phone Number is required" })} 
                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                                id="phone_number" 
                                type="number" 
                                placeholder="Phone Number" 
                            />
                            <ErrorMessage 
                                name="phone_number" 
                                errors={errors} 
                                render={({ message }) => <p className="text-red-600">{message}</p>} 
                            />
                        </div>
                        <div className="mb-4">
                            <label className="flex gap-1 text-gray-700 text-sm font-bold mb-2" htmlFor="business_name">
                                Business Name <span className="text-red-600">*</span>
                            </label>
                            <input 
                                {...register('business_name', { required: "Business Name is required" })} 
                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                                id="business_name" 
                                type="text" 
                                placeholder="Business Name" 
                            />
                            <ErrorMessage 
                                name="business_name" 
                                errors={errors} 
                                render={({ message }) => <p className="text-red-600">{message}</p>} 
                            />
                        </div>
                        <div className="mb-4">
                            <label className="flex gap-1 text-gray-700 text-sm font-bold mb-2" htmlFor="business_address">
                                Business Address <span className="text-red-600">*</span>
                            </label>
                            <textarea 
                                {...register('business_address', { required: "Business Address is required" })} 
                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                                id="business_address" 
                                placeholder="Business Address" 
                            />
                            <ErrorMessage 
                                name="business_address" 
                                errors={errors} 
                                render={({ message }) => <p className="text-red-600">{message}</p>} 
                            />
                        </div>
                        <div className="mb-4">
                            <label className="flex gap-1 text-gray-700 text-sm font-bold mb-2" htmlFor="product_type">
                                Product Type <span className="text-red-600">*</span>
                            </label>
                            <input 
                                {...register('product_type', { required: "Product Type is required" })} 
                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                                id="product_type" 
                                type="text" 
                                placeholder="eg. Window Signs" 
                            />
                            <ErrorMessage 
                                name="product_type" 
                                errors={errors} 
                                render={({ message }) => <p className="text-red-600">{message}</p>} 
                            />
                        </div>
                        <div className="mb-4">
                            <label className="flex gap-1 text-gray-700 text-sm font-bold mb-2" htmlFor="upload_file">
                                Upload File <span className="text-red-600">*</span>
                            </label>
                            <input 
                                {...register('upload_file', { required: "File is required" })} 
                                className="file:bg-orange-500 file:hover:bg-black file:text-white file:font-bold file:py-2 file:px-4 file:rounded file:border-transparent" 
                                id="upload_file" 
                                type="file" 
                                accept="image/png, image/jpeg"
                            />
                            <ErrorMessage 
                                name="upload_file" 
                                errors={errors} 
                                render={({ message }) => <p className="text-red-600">{message}</p>} 
                            />
                        </div>
                        <div className="mb-4">
                            <label className="flex gap-1 text-gray-700 text-sm font-bold mb-2" htmlFor="messages">
                                Message <span className="text-red-600">*</span>
                            </label>
                            <textarea 
                                {...register('messages', { required: "Message is required" })} 
                                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                                id="messages" 
                                placeholder="Message" 
                            />
                            <ErrorMessage 
                                name="messages" 
                                errors={errors} 
                                render={({ message }) => <p className="text-red-600">{message}</p>} 
                            />
                        </div>
                        <div className="flex items-center gap-5">
                            <button 
                                className="bg-orange-500 hover:bg-black text-white font-bold py-2 px-4 rounded" 
                                type="submit"
                            >
                                Send Message
                            </button>
                            <button 
                                className="bg-black hover:bg-orange-500 text-white font-bold py-2 px-4 rounded" 
                                type="reset"
                            >
                                RESET
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
export default RequestMessage;
